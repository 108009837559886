import React from 'react'
import ReactWhatsapp from 'react-whatsapp'
const Service = () => {
  return (
    <>    
    <div className='w-[100vw] lg:px-16 py-8 md:h-auto bg-[#101010] h-auto flex xl:flex-row xl:gap-8 xl:h-[100vh] justify-center items-center flex-col  md:flex-col md:py-8' id='Service'>  

        <div data-aos="fade-right"  className='cursor-pointer xl:w-[325px] xl:h-[465px] lg:w-[275px] lg:h-[365px] w-[70%] h-[50vh] mb-8 md:w-[320px] md:h-[400px] md:mb-4 rounded-lg bg-[#222222] flex justify-center items-center flex-col  hover:bg-[#0d6efd] cardHover xl:px-8 transition-all hover:scale-105'>
            <i className="fa-solid fa-code text-[#0d6efd] xl:text-[50px] lg:text-[40px] xl:mb-3 text-[40px] mb-2 md:text-[60px] md:mb-4"></i>
            <h2 className='xl:text-[23px] lg:text-[18px] text-white md:text-[23px] md:mb-2 text-[22px] mb-1'>Single Page </h2>
            <p className='text-[#adadad] lg:text-[14px] text-center xl:mt-[10px] md:mb-2 text-[14px] mb-1'>
            A simple single-page website can range from ₹2,000 to ₹5,000 or more, depending on factors like design complexity, responsiveness, and any additional features like contact forms, image galleries, and animations.                </p>
            <div className='flex flex-row gap-6 py-8'>
            <ReactWhatsapp number='+91 7455069625' message='I am interested in creating single page project'>
            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
            <i className=" cursor-pointer text-2xl md:text-xl text-white  md:block fa-brands fa-whatsapp"></i>
            </button> 
            </ReactWhatsapp>
            <button onClick={()=>alert("Somthink Want Worng")} class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
             Demo
            </button>
            </div>
        </div>

        <div data-aos="fade-right" data-aos-duration="800" className='cursor-pointer xl:w-[325px] xl:h-[465px] lg:w-[275px] lg:h-[365px] md:w-[320px] md:h-[400px] md:mb-4 w-[70%] h-[50vh] mb-8 rounded-lg bg-[#222222] flex justify-center items-center flex-col text-white  hover:bg-[#0d6efd] cardHover xl:px-8 transition-all hover:scale-105'>
            <i className="fa-laptop-code fa-solid text-[#0d6efd] xl:text-[50px] xl:mb-3 md:text-[60px] md:mb-4 text-[40px] mb-2 "></i>
            <h2 className='xl:text-[23px] lg:text-[18px] text-white md:text-[23px] md:mb-2 text-[22px] mb-1'>E-Commerce</h2>
            <p className='text-[#adadad] lg:text-[14px]  text-center xl:mt-[10px] md:mb-2 text-[14px] mb-1'>
            eCommerce websites are usually more complex and involve integrating payment gateways, product listings, user accounts, and more. The price for an eCommerce website can vary significantly based on the platform you're using (e.g., WooCommerce, Shopify, Magento) and the number of products you plan to sell. Prices can start from ₹10,000 or more.                </p>
            <div className='flex flex-row gap-6 py-8'>
            <ReactWhatsapp number='+91 7455069625' message='I am interested in creating E-Commerce website'>
            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
            <i className=" cursor-pointer text-2xl md:text-xl text-white  md:block fa-brands fa-whatsapp"></i>
            </button> 
            </ReactWhatsapp>     
            <button onClick={()=>alert("Somthink Want Worng")} class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
             Demo
            </button>
            </div>
        </div>

        <div data-aos="fade-right" data-aos-duration="1000" className='cursor-pointer xl:w-[325px] xl:h-[465px] lg:w-[275px] lg:h-[365px] md:w-[320px] md:h-[400px] md:mb-4 w-[70%] h-[50vh] mb-8 rounded-lg bg-[#222222] flex justify-center items-center flex-col text-white  hover:bg-[#0d6efd] cardHover xl:px-8 transition-all hover:scale-105'>
        <i className="fa-regular fa-file-code text-[#0d6efd] xl:text-[50px] md:text-[60px] md:mb-4 text-[40px] mb-2 "></i>
            <h2 className='xl:text-[23px] lg:text-[18px] text-white md:text-[23px] md:mb-2 text-[22px] mb-1'>Multi Page </h2>
            <p className='text-[#adadad] lg:text-[14px] text-center xl:mt-[10px] md:mb-2 text-[14px] mb-1'>
            The price for a multi-page website can range from ₹2,000 and ₹1,000/per Page or more. The price will depend on the number of pages, design complexity, features, content management system (CMS) integration, and any additional functionalities.                      </p>
            <div className='flex flex-row gap-6 py-8'>
            <ReactWhatsapp number='+91 7455069625' message='I am interested in creating Multi Page website'>
            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
            <i className=" cursor-pointer text-2xl md:text-xl text-white  md:block fa-brands fa-whatsapp"></i>
            </button> 
            </ReactWhatsapp>     
            <button onClick={()=>alert("Somthink Want Worng")} class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
             Demo
            </button>
            </div>
        </div>

        <div data-aos="fade-right" data-aos-duration="1300" className='cursor-pointer xl:w-[325px] xl:h-[465px] lg:w-[275px] lg:h-[365px] md:w-[320px] md:h-[400px] md:mb-4 w-[70%] h-[50vh] mb-8 rounded-lg bg-[#222222] flex justify-center items-center flex-col text-white  hover:bg-[#0d6efd] cardHover xl:px-8 transition-all hover:scale-105'>
        <i className="fa-regular fa-file-code text-[#0d6efd] xl:text-[50px] md:text-[60px] md:mb-4 text-[40px] mb-2 "></i>
            <h2 className='xl:text-[23px] lg:text-[18px] text-white md:text-[23px] md:mb-2 text-[22px] mb-1'>College Project</h2>
            <p className='text-[#adadad] lg:text-[14px] text-center xl:mt-[10px] md:mb-2 text-[14px] mb-1'>
            Consider the scope of the college project. If it's a relatively simple project that won't take much time, you might charge a lower fee. For more complex projects, you can adjust your price accordingly.</p>            <div className='flex flex-row gap-6 py-8'>
            <ReactWhatsapp number='+91 7455069625' message='I am interested in creating college projects, and I would like your assistance in developing them.'>
            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
            <i className=" cursor-pointer text-2xl md:text-xl text-white  md:block fa-brands fa-whatsapp"></i>
            </button> 
            </ReactWhatsapp>     
            <button onClick={()=>alert("Somthink Want Worng")} class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
             Demo
            </button>
            </div>
        </div>
    </div>
    </>
  )
}

export default Service