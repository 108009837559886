import React from 'react'
import './style.css'

const Section_1 = () => {
  return (
    <>
    <div className='w-[100vw] xl:h-[70vh] bg-[#222222] flex items-center justify-around flex-col xl:flex-row md:flex-col md:h-auto md:p-6' id='About'>
        <div data-aos="fade-up" data-aos-anchor-placement="top-center" className='px-8 border-red-5'>
            <h2 className='xl:text-[8vh] lg:text-[6vh] lg:mx-[40px] font-sans font-extrabold text-white xl:-mx-8 md:text-3xl md:mb-2 text-[35px] mb-2' >About <span className='text-[#0d6efd]'>  me</span></h2>
            <p className=' xl:w-[800px] lg:w-[500px] lg:mx-[40px] lg:text-[15px] lg:my-4 xl:-mx-8 xl:text-[18px] xl:my-10 text-white md:text-[14px] mb-6 text-[15px]'>
Hello, I'm Abhishek Kumar Majumdar, a passionate Full Stack Web Developer and B.Tech Computer Science student deeply enthusiastic about Machine Learning. I specialize in crafting dynamic web applications and, through three internships, have honed my skills. Committed to continuous learning, I'm dedicated to building innovative solutions. Let's connect and explore the possibilities together!            </p>
        </div>
        <div className='grid   grid-cols-6 grid-rows-3 my-6 gap-6 text-[3vh] xl:order-last  xl:grid-cols-4 xl:gap-8 md:grid-cols-6 md:grid-rows-3  md:gap-8 md:p-10 '>
        <i className="xl:text-6xl lg:text-4xl md:text-5xl cursor-pointer hover:text-white hover:scale-110 transition-all text-[#0d6efd] fa-brands fa-html5"></i>
        <i className="xl:text-6xl lg:text-4xl md:text-5xl cursor-pointer hover:text-white hover:scale-110 transition-all text-[#0d6efd] fa-brands fa-css3-alt"></i>
        <i className="xl:text-6xl lg:text-4xl md:text-5xl cursor-pointer hover:text-white hover:scale-110 transition-all text-[#0d6efd] fa-brands fa-square-js"></i>
        <i className="xl:text-6xl lg:text-4xl md:text-5xl cursor-pointer hover:text-white hover:scale-110 transition-all text-[#0d6efd] fa-solid fa-database"></i>
        <i className="xl:text-6xl lg:text-4xl md:text-5xl cursor-pointer hover:text-white hover:scale-110 transition-all text-[#0d6efd] fa-brands fa-node"></i>
        <i className="xl:text-6xl lg:text-4xl md:text-5xl cursor-pointer hover:text-white hover:scale-110 transition-all text-[#0d6efd] fa-brands fa-react"></i>
        <i className="xl:text-6xl lg:text-4xl md:text-5xl cursor-pointer hover:text-white hover:scale-110 transition-all text-[#0d6efd] fa-brands fa-vuejs"></i>
        <i className="xl:text-6xl lg:text-4xl md:text-5xl cursor-pointer hover:text-white hover:scale-110 transition-all text-[#0d6efd] fa-brands fa-bootstrap"></i>
        <i className="xl:text-6xl lg:text-4xl md:text-5xl cursor-pointer hover:text-white hover:scale-110 transition-all text-[#0d6efd] fa-brands fa-github"></i>
        <i className="xl:text-6xl lg:text-4xl md:text-5xl cursor-pointer hover:text-white hover:scale-110 transition-all text-[#0d6efd] fa-brands fa-figma"></i>
        <i className="xl:text-6xl lg:text-4xl md:text-5xl cursor-pointer hover:text-white hover:scale-110 transition-all text-[#0d6efd] fa-brands fa-python"></i>
                </div>
        </div></>
  )
}

export default Section_1