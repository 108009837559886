import React from 'react'
import './style.css'

const Skill = () => {
  return (
    <>
    <div className='w-[100vw] h-auto bg-[#101010] py-10 px-8 xl:px-32 md:px-16' id="Skill">
    <h2 className='xl:text-[7vh] lg:text-[6vh] font-sans font-bold text-white text-center text-[37px] w-[100vw] xl:w-auto '><span className='text-[#0d6efd]'> My</span> Skills</h2>
      <div className='flex flex-row justify-around py-4 xl:py-16 md:py-8'>
        <div className=''>
          <ul className='text-white text-[15px]  xl:text-[20px] lg:text-[18px] md:text-[16px] font-sans font-semibold tracking-[1px] skillLi'>
            <li >Python</li>
            <li >C++</li>
            <li >C</li>
            <li >mySQL</li>
            <li>Rest API</li>
            <li >Mechine Learning</li>
            <li >Tensorflow</li>
            <li > Data Analysis & Analytics</li>
            <li>MongoDB</li>



           

          </ul>
        </div>
        <div>
        <ul className='text-white text-[15px]  xl:text-[20px] lg:text-[18px] md:text-[16px] font-sans font-semibold tracking-[1px] skillLi'>
        <li >Material UI</li>
        <li>HTML 5</li>
        <li>CSS 3</li>
        <li>JavaScript</li>
        <li>React JS</li>
        <li>Node JS</li>
        <li>Vue JS</li>
        <li>Flask</li>
        <li>github</li>

       



          </ul >
        </div>
      </div>
    </div>
    </>
  )
}

export default Skill