import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion';
import Navbar from './Navbar'
import Section_1 from './Section_1';
import {useTypewriter , Cursor} from 'react-simple-typewriter';
import ReactWhatsapp from 'react-whatsapp';
import './style.css'
const Hero = () => {
    const [text] = useTypewriter({
        words:['Freelancer','Web Developer' , 'Software Developer','Open Source Contributor'],
        loop:{},
        deleteSpeed:80,
        Cursor,
        cursorStyle:"|"
    });
    const[mousePosition,setMousePosition] = useState({
      x:0,
      y:0
  });
  const[cursorVariant,setCursorVariant] =useState("default");
  
  useEffect(()=>{
          const moveMouse= e  =>{
              setMousePosition({
                  x:e.clientX,
                  y:e.clientY
              })
          }
          window.addEventListener("mousemove",moveMouse);
          return ()=>{
              window.removeEventListener("mousemove",moveMouse);
  
          }
      });
      const variants = {
          default:{
              x:mousePosition.x - 15,
              y:mousePosition.y -15
          },
          text:{
              height:100,
              width:100,
              x:mousePosition.x - 50,
              y:mousePosition.y -50,
              background:"blue",
              mixBlendMode:"difference"
          }
      }
      const textEnter = () =>{setCursorVariant("text")}
      const textLeave = () =>{setCursorVariant("default")}
  return (
    <>
    <div className="w-[100vw] lg:h-[100vh] h-auto  bg-[#101010] relative truncate" id='Hero'>
    <Navbar/>
    <div className='w-[100vw] h-[60vh] lg:h-[100vh] flex items-center justify-around  flex-col lg:flex-row'>
        <div className='order-last lg:h-auto h-[40px]  lg:order-first mt-6 mb-16 md:px-8'>
            <h1 className='xl:text-[10vh] text-[5vh] text-white font-sans font-semibold lg:text-[8vh] md:text-[6vh] ' onMouseEnter={textEnter} onMouseLeave={textLeave}>Hi, I'm Abhishek</h1>
            <h3 className='xl:text-[6.5vh] text-[3vh] text-[#0d6efd] font-sans font-semibold z-2 transition-all xl:h-[120px] lg:text-[4.5vh] md:text-[4vh] md:h-[37px] lg:h-[60px]'>{text}</h3>
            <div className='flex flex-row gap-5 relative z-10'> 
            <a href='https://www.linkedin.com/in/theabhishekmajumdar/'> <i className=" text-2xl  md:text-xl cursor-pointer text-white hidden md:block fa-brands fa-linkedin"></i></a>
           <a href='https://github.com/AbhishekKumarMajumdar'> <i className="text-2xl md:text-xl text-white hidden md:block fa-brands fa-square-github"></i></a>
           <a href='https://stackoverflow.com/users/22211845/abhishek-kumar-majumdar'><i className="text-2xl md:text-xl text-white hidden md:block fa-brands fa-stack-overflow"></i></a>
           <ReactWhatsapp number='+91 7455069625' message='I am interested'><i className=" cursor-pointer text-2xl md:text-xl text-white hidden md:block fa-brands fa-whatsapp"></i></ReactWhatsapp>
           </div>
        </div>
        <div className='xl:w-[450px] xl:h-[450px] xl:border-b-8  xl:border-r-8  md:w-[240px] md:h-[240px] h-[250px] w-[250px] md:border-b-2 md:border-r-2 border-r-4 border-b-4 py-0 border-[#0d6efd] relative z-8 lg:w-[300px] lg:h-[300px] lg:border-b-4 lg:border-r-4'>
            <img src="abhiR.png" className='w-[100%] h-auto my-[-55px] xl:-my-[100px] lg:-my-[68px] md:-my-[53px]'alt='Abhishek kumar Majumdar '></img>
        </div>
    </div>
    <div className='w-[500px] h-[500px] mt-0 bg-[rgba(250,250,250,0.09)] absolute -left-[80px]  -top-[100px] rounded-full -z-9 sbg'>
      
    </div>
    <div>
            <ul className="circles hidden md:block">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
            </ul>
    </div>
    </div>
    <Section_1/>
    </>
  )
}

export default Hero