import React from 'react'
import Navbar from '../components/Navbar'

const Admin = () => {
  return (
    <>
    <div className='bg-[#09131f] w-[100vw] h-[100vh]'>
    <h2 className='xl:text-[7vh] lg:text-[6vh] font-sans font-bold text-white text-center text-[37px] w-[100vw] xl:w-auto '><span className='text-[#0d6efd]'> Walcome </span> Abhishek</h2>
        <div className='w-[100vw] h-[40vh]  flex flex-row justify-around items-center'  >
            <div className='color-white rounded-md cursor-pointer w-[250px] h-[250px] flex flex-col justify-center items-center bg-[#9d49f0] ' >
                <h3 className='text-white font-bold text-[55px]'>10</h3>
                <h3 className='text-white font-bold text-[35px]'>Internship</h3>
            </div>
            <div className='color-white rounded-md cursor-pointer w-[250px] h-[250px] flex flex-col justify-center items-center bg-[#49e5f0]  ' >
            <h3 className='text-white font-bold text-[55px]'>10</h3>
                <h3 className='text-white font-bold text-[35px]'>Internship</h3>
            </div>
            <div className='color-white rounded-md cursor-pointer w-[250px] h-[250px] flex flex-col justify-center items-center bg-[#f04949] ' >
            <h3 className='text-white font-bold text-[55px]'>10</h3>
                <h3 className='text-white font-bold text-[35px]'>Internship</h3>
            </div>
            <div className='color-white rounded-md cursor-pointer w-[250px] h-[250px] flex flex-col justify-center items-center bg-[#6df049] ' >
            <h3 className='text-white font-bold text-[55px]'>10</h3>
                <h3 className='text-white font-bold text-[35px]'>Internship</h3>
            </div>

        </div>

        
    </div>
    </>
  )
}

export default Admin