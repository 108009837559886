import React from 'react'
import {Link} from 'react-scroll'

const Footer = () => {
  return (
    <>
    <div className='w-[100vw] h-[100px] bg-[#101010] text-center text-white'><Link to="Hero" spy={true} smooth={true} duration={500} className="py-2 px-4  bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75">
    <i class="fa-solid fa-arrow-up"></i> To The Top
</Link><br/><div className='mt-8 md:text-[18px] text-[12px]'>© All Rights Reserved By <a href='https://www.linkedin.com/in/theabhishekmajumdar/' className='text-[#0d6efd] cursor-pointer'>Abhishek Kumar Majumdar</a> (2023)</div></div>
    </>
  )
}

export default Footer