export const  projectData = [
    {
        id: 1,
        name:"Weather Report",
        frameWork : "React JS With API",
        link:"https://chikuweather.netlify.app/",
        dis:"Using React.js and real-time API integration, I have developed a website where users can select their city and instantly view the corresponding weather report."

    },
    {
        id:2,
        name:"Todo LIst",
        frameWork:"React JS",
        link:"https://chikutodolist.netlify.app/",
        dis:"I have created a TodoList website using React.js, which allows users to create, edit, and delete their personalized tasks."
    },
    {
        id:3,
        name:"QR Generator",
        frameWork:"React JS With API",
        link:"https://chikuqrgenerator.netlify.app/",
        dis:"I have developed a QR Generator website that provides users with the flexibility to choose the height and width of the QR code. Users can simply enter the desired content they want to convert into a QR code."
    },
    {
        id:4,
        name:"Color Basket ",
        frameWork:"React JS ",
        link:"https://colorbasket.netlify.app/",
        dis:"I crafted a user-friendly color code management feature in a React project, facilitating effortless color code utilization for frontend developers. This enhancement streamlines the design process and underscores my dedication to simplifying intricate tasks."
    },
    {
        id:5,
        name:"Topic Trove",
        frameWork:"MERN Development",
        link:"https://topictrove.shop/",
        dis:"Explore a comprehensive hub for students! Find papers, syllabi, and roadmaps all in one place. Simplify your academic journey with us"
    }
]