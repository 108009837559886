import React from 'react'

const Contect = () => {

  return (
    <>
    <div className='xl:w-[100vw]  lg:h-[450px] bg-[#101010] xl:h-auto xl:px-8 md:h-auto py-10' id='Contect'>
    <h2 className='xl:text-[7vh] md:text-[7vh] md:py-8   lg:text-[6vh] font-sans font-bold text-white text-center text-[37px] w-[100vw] '><span className='text-[#0d6efd]'> My</span> Contact</h2>
    <div className='flex xl:flex-row md:flex-col flex-col justify-around items-center xl:my-12 lg:my-6'>
        <div className='xl:w-[700px] xl:h-[350px] lg:w-[450px] lg:h-[300px] md:w-[100%] px-16 py-2 md:px-16 md:h-[300px] w-[100vw] h-[300px]'><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13949.084025563256!2d79.51155325260189!3d29.06813570523218!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39a0865bd205480f%3A0x2fc5f26153158893!2sLalkuan%2C%20Uttarakhand%20262402!5e0!3m2!1sen!2sin!4v1689274060474!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></div>
        <div className='xl:w-[700px] xl:h-[350px] lg:w-[450px] lg:h-[300px]  md:w-[90%] w-[90%]  px-16 py-4 h-auto md:px-16 md:h-[300px] md:py-16 flex justify-center xl:flex-col gap-4'>
            <div className='xl:my-3 lg:my-1 '>
            <i className="fa-solid fa-location-dot text-[#0d6efd] xl:text-3xl lg:text-2xl xl:my-2 text-[30px] mb-4 md:text-[30px] md:mb-4"></i>
                <h2 className='font-sans text-white xl:tracking-[7px] tracking-[4px] xl:text-[18px] lg:text-[16.5px] md:text-[16.5px] text-[10px]'>CURRENT ADDRESS</h2>
                <p className='font-sans text-[#adadad] lg:text-[15px] text-[12px]'>Uttarakhant , India</p>
            </div>
            <div className='xl:my-3 lg:my-1'>
            <i className="fa-solid fa-phone text-[#0d6efd] xl:text-3xl lg:text-2xl xl:my-2 md:text-[30px] text-[30px] mb-4 md:mb-4"></i>
                <h2 className='font-sans text-white xl:tracking-[7px] tracking-[4px] xl:text-[18px] lg:text-[16.5px] md:text-[16.5px] text-[10px] '>CONTACT NUMBER</h2>
                <p className='font-sans text-[#adadad] lg:text-[15px] text-[12px]'>+91 7455069625</p>
            </div>
            <div className='xl:my-3 lg:my-1'>
            <i className="fa-solid fa-envelope text-[#0d6efd] xl:text-3xl lg:text-2xl xl:my-2 text-[30px] mb-4 md:text-[30px] md:mb-4"></i>
                <h2 className='font-sans text-white xl:tracking-[7px] tracking-[4px] xl:text-[18px] lg:text-[16.5px] md:text-[16.5px] text-[10px]'>E - MAIL</h2>
                <p className='font-sans text-[#adadad] lg:text-[15px] text-[12px]'>Pk006604@gmail.com</p>
            </div>
        </div>
    </div>
    </div>
    </>
  )
}

export default Contect