import React, { useEffect } from 'react'
import './style.css'
import Aos from 'aos'
import 'aos/dist/aos.css'
const Card = () => {
  useEffect(()=>{
    Aos.init()
  },[])
  return (
    <>
    <div className='w-[100vw] py-8 md:h-auto bg-[#101010] h-auto flex xl:flex-row xl:gap-8 xl:h-[100vh] justify-center items-center flex-col  md:flex-col md:py-8'>
        <div data-aos="fade-right" className='xl:w-[325px] xl:h-[465px] lg:w-[275px] lg:h-[365px] w-[70%] h-[34vh] mb-8 md:w-[320px] md:h-[400px] md:mb-4 rounded-lg bg-[#222222] flex justify-center items-center flex-col  hover:bg-[#0d6efd] cardHover xl:px-8 transition-all hover:scale-105'>
            <i className="fa-solid fa-palette text-[#0d6efd] xl:text-[50px] lg:text-[40px] xl:mb-3 text-[40px] mb-2 md:text-[60px] md:mb-4"></i>
            <h2 className='xl:text-[23px] lg:text-[18px] text-white md:text-[23px] md:mb-2 text-[22px] mb-1'>Front-End Development</h2>
            <p className='text-[#adadad] lg:text-[14px] text-center xl:mt-[10px] md:mb-2 text-[14px] mb-1'>I have a proven track record of creating websites for my clients, and I am proud to say that this current portfolio website is another project that I personally designed and developed.</p>
            <h2 className='xl:text-[23px] lg:text-[18px] text-white xl:mt-[10px] md:text-[23px] md:mb-2 text-[22px] mb-1'>Tools I use</h2>
            <p className='text-[#adadad] lg:text-[14px]  text-center xl:mt-[10px]'>HTML, CSS, JavaScript, React JS</p>
        </div>
        <div data-aos="fade-right" data-aos-duration="1000" className='xl:w-[325px] xl:h-[465px] lg:w-[275px] lg:h-[365px] md:w-[320px] md:h-[400px] md:mb-4 w-[70%] h-[34vh] mb-8 rounded-lg bg-[#222222] flex justify-center items-center flex-col text-white  hover:bg-[#0d6efd] cardHover xl:px-8 transition-all hover:scale-105'>
        <i className="fa-sharp fa-solid fa-gears text-[#0d6efd] xl:text-[50px] xl:mb-3 md:text-[60px] md:mb-4 text-[40px] mb-2 "></i>
            <h2 className='xl:text-[23px] lg:text-[18px] text-white md:text-[23px] md:mb-2 text-[22px] mb-1'>Machine Learning</h2>
            <p className='text-[#adadad] lg:text-[14px]  text-center xl:mt-[10px] md:mb-2 text-[14px] mb-1'>I have a proven track record of creating websites for my clients, and I am proud to say that this current portfolio website is another project that I personally designed and developed.</p>
            <h2 className='xl:text-[23px] lg:text-[18px] text-white xl:mt-[10px] md:text-[23px] md:mb-2 text-[22px] mb-1'>Packages I use</h2>
            <p className='text-[#adadad] text-center xl:mt-[10px]'>Numpy, Pandas, TenserFlow, NLTK</p>        </div>
        <div data-aos="fade-right" data-aos-duration="1200" className='xl:w-[325px] xl:h-[465px] lg:w-[275px] lg:h-[365px] md:w-[320px] md:h-[400px] md:mb-4 w-[70%] h-[34vh] mb-8 rounded-lg bg-[#222222] flex justify-center items-center flex-col text-white  hover:bg-[#0d6efd] cardHover xl:px-8 transition-all hover:scale-105'>
        <i className="fa-solid fa-compass text-[#0d6efd] xl:text-[50px] md:text-[60px] md:mb-4 text-[40px] mb-2 "></i>

            <h2 className='xl:text-[23px] lg:text-[18px] text-white md:text-[23px] md:mb-2 text-[22px] mb-1'>Back-End Development</h2>
            <p className='text-[#adadad] lg:text-[14px] text-center xl:mt-[10px] md:mb-2 text-[14px] mb-1'>
            I have a strong track record of developing websites with backend for clients. This includes e-commerce websites, as well as mini projects with APIs.</p>  
                      <h2 className='xl:text-[23px] lg:text-[18px] text-white xl:mt-[10px] md:text-[23px] md:mb-2 text-[22px] mb-1'>Tooles I use</h2>
            <p className='text-[#adadad] lg:text-[14px]  text-center xl:mt-[10px]'>Node JS, MongoDB</p>        </div>
    </div>
    </>
  )
}

export default Card