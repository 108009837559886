import './App.css';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Card from './components/Card';
import Experence from './components/Experence';
import Skill from './components/Skill';
import Project from './components/Project';
import Count from './components/Count';
import Contect from './components/Contect';
import Footer from './components/Footer';
import {projectData} from './API/Project'
import {Experience} from './API/Exp'
import {AwordData} from './API/Aword'
import Loader from './components/Loader';
import { useState, useEffect } from 'react';
import {BrowserRouter as Router , Routes , Route, BrowserRouter } from 'react-router-dom'
import Admin from './Admin/Admin';
import Main from './main'
import ErrorPage from './components/ErrorPage';
import { motion } from 'framer-motion';

import Template from './components/Template';
function App() {
  const [loader,setLoader] = useState(false)
  const docTitle = document.title;
  window.addEventListener("blur",()=>{
      document.title = "come back !";
  });
      window.addEventListener("focus",()=>{
          document.title = docTitle;
      });

  useEffect(() => {
    setLoader(false)
  setTimeout(()=>{setLoader(true)},3000)
  },[])
  
  return (
<>
<div className='overflow-x-hidden '>
{loader?"":(<Loader/>)}
<Router>
  <Routes>
    <Route path='/' element={<Main/>} />
    <Route path='*' element={<ErrorPage/>} />
    <Route path='/admin' element={<Admin/>} />
  </Routes>
</Router>
{/* <Hero/>
<Card/>
<Experence Experience={Experience} AwordData={AwordData}/>
<Skill/>
<Project projectData={projectData}/>
<Count projectData={projectData} Experience={Experience}/>
<Contect/>
<Footer/> */}
</div>
</>
  );
}

export default App;
