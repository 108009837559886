import React, { useState } from 'react'
import CountUp from 'react-countup'
import ScrollTrigger from 'react-scroll-trigger'
import './style.css'
const Count = ({projectData , Experience}) => {
  const [count,setCount] = useState(false);
  return (
    <>
    <ScrollTrigger onEnter={()=>setCount(true)} onExit={()=>setCount(false)}>
    <div className='w-[100vw] xl:h-[300px] h-auto pb-8 xl:grid grid justify-center items-center xl:grid-cols-4 md:grid-cols-2 countBg md:px-32 lg:gap-16'>

        <div className='flex flex-col  items-center '>
          <div className='text-[#0d6efd] font-sans text-[50px] xl:text-[75px] md:text-[60px] lg:text-[60px] relative z-10'>
           { count && <CountUp start={0} end={`${projectData.length}`} duration={1} delay={0}/>}
          </div>
          <div className='text-white font-sans xl:text-[25px] text-[25px] mb-2 md:text-[25px]  lg:text-[20px] relative z-10'>Personal Projects</div>
        </div>
        <div className='flex flex-col items-center '>
          <div className='text-[#0d6efd] font-sans text-[50px] xl:text-[75px] lg:text-[60px] md:text-[60px] relative z-10'>
          { count && <CountUp start={0} end={`${Experience.length}`} duration={1} delay={0}/>}
            </div>
          <div className='text-white font-sans xl:text-[25px] text-[25px] mb-2 lg:text-[20px] md:text-[25px] relative z-10'>Internships Done</div>
        </div>
        <div className='flex flex-col items-center '>
          <div className='text-[#0d6efd] font-sans text-[50px] xl:text-[75px] lg:text-[60px] md:text-[60px] relative z-10'>
          { count && <CountUp start={0} end={2} duration={1} delay={0}/>}
            </div>
          <div className='text-white font-sans xl:text-[25px] text-[25px] mb-2 lg:text-[20px] md:text-[25px] relative z-10'>Freelance Projects</div>
        </div>
        <div className='flex flex-col items-center '>
          <div className='text-[#0d6efd] font-sans text-[50px] xl:text-[75px] lg:text-[60px] md:text-[60px] relative z-10'>
          { count && <CountUp start={0} end={6} duration={1} delay={0}/>}
            </div>
          <div className='text-white font-sans xl:text-[25px] text-[25px] mb-2 lg:text-[20px] md:text-[25px] relative z-10'>Work Exp*(Months)</div>
        </div>

    </div>
    </ScrollTrigger>
    </>
  )
}

export default Count