import { render } from '@testing-library/react'
import React from 'react'

const Project = ({projectData}) => {
  return (
    <>
        <div className='w-[100vw] h-auto bg-[#222222] py-10 px-8 xl:px-32 md:px-8 lg:px-16' id='Project'>
        <h2 className='xl:text-[7vh] lg:text-[6vh] font-sans font-bold text-white w-[100vw] text-center text-[37px] xl:w-auto '>My <span className='text-[#0d6efd]'>  Project</span></h2>
        <div data-aos="fade-right" className='w-[100%] h-auto'>
            <div className='grid grid-cols-1 xl:grid-cols-2'>
                {projectData.map((e)=>{
                  return (
                     <div className='w-[100%] xl:w-[90%] h-auto p-4 xl:p-8 lg:p-6 rounded-3xl bg-[#101010] text-white transition-all Box mt-8'>
                  <h2 className='text-[23px] lg:text-[3.5vh] xl:mb-2 font-semibold'>{e.name}</h2>
                  <p className='text-[#adadad] xl:mb-1'>( {e.frameWork})</p>
                  <p className='text-[20px] tracking-[3px] text-[#0d6efd] mb-1'><a href={`${e.link}`}>Project Link <i class="fa-solid fa-arrow-right"></i></a> </p>
                  <p className='xl:text-[17px] text-[13px] lg:text-[15px] lg:mt-2 xl:mt-6'>{e.dis}</p>
            </div>)
                 
                })}

{/* 
                <div className='w-[100%] xl:w-[90%] h-auto p-4 xl:p-8 lg:p-6 rounded-3xl bg-[#101010] text-white transition-all Box mt-8'>
                      <h2 className='text-[23px] lg:text-[3.5vh] xl:mb-2 font-semibold'>Weather Report</h2>
                      <p className='text-[#adadad] xl:mb-1'>( React JS with API )</p>
                      <p className='text-[20px] tracking-[3px] text-[#0d6efd] mb-1'><a href='https://chikuweather.netlify.app/'>Project Link <i class="fa-solid fa-arrow-right"></i></a> </p>
                      <p className='xl:text-[17px] text-[13px] lg:text-[15px] lg:mt-2 xl:mt-6'>
                      Using React.js and real-time API integration, I have developed a website where users can select their city and instantly view the corresponding weather report.                        </p>
                </div>

                <div className='w-[100%] lg:p-6 h-auto p-4 xl:p-8 rounded-3xl bg-[#101010] text-white transition-all Box mt-8'>
                      <h2 className='text-[23px] g:text-[3.5vh] xl:mb-2 font-semibold'>Todo LIst</h2>
                      <p className='text-[#adadad] xl:mb-1'>( React JS )</p>
                      <p className='text-[20px] tracking-[3px] text-[#0d6efd] mb-1'><a href='https://chikutodolist.netlify.app/'>Project Link <i class="fa-solid fa-arrow-right"></i></a> </p>
                      <p className='text-[13px] xl:text-[17px] lg:text-[15px] lg:mt-2 xl:mt-6'>
                      I have created a TodoList website using React.js, which allows users to create, edit, and delete their personalized tasks.                                             </p>
                </div>

                <div className='w-[100%] xl:w-[90%] lg:p-6 h-auto p-4 xl:p-8 rounded-3xl bg-[#101010] text-white transition-all Box mt-8'>
                      <h2 className='text-[23px] g:text-[3.5vh] xl:mb-2 font-semibold'>QR Generator </h2>
                      <p className='text-[#adadad] xl:mb-1'>( React JS with API )</p>
                      <p className='text-[20px] tracking-[3px] text-[#0d6efd] mb-1'><a href='https://chikuqrgenerator.netlify.app/'>Project Link <i class="fa-solid fa-arrow-right"></i></a> </p>
                      <p className=' text-[13px] xl:text-[17px] lg:text-[15px] lg:mt-2 xl:mt-6'>
                      I have developed a QR Generator website that provides users with the flexibility to choose the height and width of the QR code. Users can simply enter the desired content they want to convert into a QR code.                                            </p>
                </div> */}
            </div>
           
    </div>
</div>
    </>
  )
}

export default Project