import React, { useState } from 'react'

import ReactWhatsapp from 'react-whatsapp';
import Mouse from './Mouse';
const Navbar = () => {
    const [show,setShow] = useState(false);
      return (
    <>
    {/* <Headroom> */}
    <Mouse/>
    <header className='bg-transparent p-5 relative z-99' > 
        <nav className='flex justify-between items-center w-[92%] mx-auto transition ease-linear' >
            <div className='cursor-pointer xl:text-[20px] text-white lg:text-[15px] md:text-[15px]'><a href="/"><img className='w-[70px] cursor-pointer' alt='logo' src='logo.png'/></a></div>
            <div className={`md:static md:min-h-fit md:w-auto absolute ${show?"block":"hidden"} lg:block md:block min-h-[60vh]  h-auto z-10 left-0 px-16 py-6 my-[-100px] top-[100%] w-full items-center xl:px-5 md:bg-transparent xl:bg-transparent bg-black`}>
                <ul className='flex md:flex-row flex-col md:items-center md:gap-[4vw] gap-8 capitalize icon-change  text-white'>
                    <li className='font-sans font-medium xl:text-lg lg:text-[20px] md:text-[13px] cursor-pointer active:text-[#0d6efd]'><a href="/">Home </a></li>
                    <li className='font-sans font-medium xl:text-lg lg:text-[15px] md:text-[13px] cursor-pointer'><a href="#About">About us</a></li>
                    <li className='font-sans font-medium xl:text-lg lg:text-[15px] md:text-[13px] cursor-pointer'><a href="#Skill">Skills</a></li>
                    <li className='font-sans font-medium xl:text-lg lg:text-[15px] md:text-[13px] cursor-pointer'><a href="#Project">Projects</a></li>
                    <li className='font-sans font-medium xl:text-lg lg:text-[15px] md:text-[13px] cursor-pointer'><a href="#Service">Services</a></li>
                    <li className='font-sans font-medium xl:text-lg lg:text-[15px] md:text-[13px] cursor-pointer'><a href="#Contect">Contects</a></li>
                    <div className='flex gap-[6.5vw] xl:hidden'>
            <ReactWhatsapp number='+91 7455069625' message='I am interested'><i className="xl:text-2xl lg:text-[20px] cursor-pointer relative z-10 text-white  font-[25px] md:block fa-brands fa-whatsapp"></i></ReactWhatsapp>
            <a href='https://www.linkedin.com/in/theabhishekmajumdar/'> <i className="xl:text-2xl lg:text-[20px] cursor-pointer relative z-10 text-white  font-[25px] md:block fa-brands fa-linkedin"></i></a>
            <a href='https://github.com/AbhishekKumarMajumdar'> <i className="xl:text-2xl lg:text-[20px] cursor-pointer relative z-10 text-white  md:block font-[25px] fa-brands fa-square-github"></i></a>
            <a href='https://stackoverflow.com/users/22211845/abhishek-kumar-majumdar'> <i className="xl:text-2xl lg:text-[20px] cursor-pointer text-white  font-[25px] md:block relative z-10 fa-brands fa-stack-overflow"></i></a>
            </div>
                </ul>
            </div>
            <div className='flex gap-[1.5vw]'>
            <ReactWhatsapp number='+91 7455069625' message='I am interested'><i className="xl:text-2xl lg:text-[20px] cursor-pointer relative z-10 text-white hidden md:block fa-brands fa-whatsapp"></i></ReactWhatsapp>
            <a href='https://www.linkedin.com/in/theabhishekmajumdar/'> <i className="xl:text-2xl lg:text-[20px] cursor-pointer relative z-10 text-white hidden md:block fa-brands fa-linkedin"></i></a>
            <a href='https://github.com/AbhishekKumarMajumdar'> <i className="xl:text-2xl lg:text-[20px] cursor-pointer relative z-10 text-white hidden md:block fa-brands fa-square-github"></i></a>
            <a href='https://stackoverflow.com/users/22211845/abhishek-kumar-majumdar'> <i className="xl:text-2xl lg:text-[20px] cursor-pointer text-white hidden md:block relative z-10 fa-brands fa-stack-overflow"></i></a>
            <i className={`text-3xl relative z-10 text-white cursor-pointer md:hidden fa-solid fa-${show?"xmark":"bars"}`} onClick={()=>{setShow(!show)}}></i>
            </div>
        </nav>
    </header>
    {/* </Headroom> */}
  </>
  )
};

export default Navbar;