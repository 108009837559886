import React, { useEffect, useState } from 'react'
import './App.css';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Card from './components/Card';
import Experence from './components/Experence';
import Skill from './components/Skill';
import Project from './components/Project';
import Count from './components/Count';
import Contect from './components/Contect';
import Footer from './components/Footer';
import {projectData} from './API/Project'
import {Experience} from './API/Exp'
import {AwordData} from './API/Aword'
import Admin from './Admin/Admin';
import Service from './components/Service';
import { motion } from 'framer-motion';
import Mouse from './components/Mouse';

const Main = () => {
  const[mousePosition,setMousePosition] = useState({
    x:0,
    y:0
});
const[cursorVariant,setCursorVariant] =useState("default");

useEffect(()=>{
    const moveMouse= e  =>{
        setMousePosition({
            x:e.clientX,
            y:e.clientY
        })
    }
    window.addEventListener("mousemove",moveMouse);
    return ()=>{
        window.removeEventListener("mousemove",moveMouse);

    }
});
const variants = {
    default:{
        x:mousePosition.x - 15,
        y:mousePosition.y -15
    },
    text:{
        height:100,
        width:100,
        x:mousePosition.x - 50,
        y:mousePosition.y -50,
        background:"white",
        mixBlendMode:"difference"
    }
}

const textEnter = () =>{setCursorVariant("text")}
const textLeave = () =>{setCursorVariant("default")}
  return (
    <>
    <motion.div className=' md:block hidden w-[30px] h-[30px] rounded-[50%] bg-[#0d6efd] z-50 fixed left-0 top-0 pointer-events-none'variants={variants} animate={cursorVariant}/>
    <Hero/>
<Card/>
<Experence Experience={Experience} AwordData={AwordData}/>
<Skill/>
<Project projectData={projectData}/>
<Service/>
<Count projectData={projectData} Experience={Experience}/>
<Contect/>
<Footer/>
    </>
  )
}

export default Main