import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion';
const Mouse = () => {
    const[mousePosition,setMousePosition] = useState({
        x:0,
        y:0
    });
    const[cursorVariant,setCursorVariant] =useState("default");
    
    useEffect(()=>{
            const moveMouse= e  =>{
                setMousePosition({
                    x:e.clientX,
                    y:e.clientY
                })
            }
            window.addEventListener("mousemove",moveMouse);
            return ()=>{
                window.removeEventListener("mousemove",moveMouse);
    
            }
        });
        const variants = {
            default:{
                x:mousePosition.x - 15,
                y:mousePosition.y -15
            },
            text:{
                height:100,
                width:100,
                x:mousePosition.x - 50,
                y:mousePosition.y -50,
                background:"white",
                mixBlendMode:"difference"
            }
        }
        const textEnter = () =>{setCursorVariant("text")}
        const textLeave = () =>{setCursorVariant("default")}
  return (
    <div></div>
  )
}

export default Mouse