import React from 'react'
import { Audio, Puff } from 'react-loader-spinner'
const Loader = () => {
  return (
    <>
    <div className='bg-[#101010] z-[99999] overflow-hidden fixed w-[100%] h-[100vh] flex justify-center items-center'>
    <Puff
  height="100"
  width="100"
  radius="9"
  color="#0d6efd"
  ariaLabel="loading"
  wrapperStyle
  wrapperClass
/></div>
    </>
  )
}

export default Loader