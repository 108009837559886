export const Experience = [
   {
    id:1,
    position:"Product Engineering Intern",
    domain:"Machine Learning",
    company:"Suvidha Foundation",
    date:"Oct 2022 - Apr 2023",
    dis:"During this internship, we will be working with NLTK to gain hands-on experience and develop a deeper understanding of how NLTK functions."
   },
   {
      id:2,
      position:"Programmer",
      domain:"C++ Programming",
      company:"CodeSoft",
      date:"Aug 2023 - Oct 2023",
      dis:"This internship is straightforward, involving tasks related to C++ that we need to complete."
   },
   {
      id:3,
      position:"Full Stack Developer",
      domain:"Redux & React js",
      company:"eKalakaar",
      date:"Pursuing",
      dis:"Interning as a Full Stack Developer at eKalakaar, I contribute to innovative solutions, enhancing user experiences with a talented team"
   }
   
]