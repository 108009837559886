import React from 'react'
import './style.css'

const Experence = ({Experience,AwordData}) => {
  return (
    <>
    <div className='h-auto w-[100vw] bg-[#222222] px-8 py-10 lg:px-16 lg:py-6 md:px-8 md:py-5' >
    <h2 className='xl:text-[7vh] lg:text-[6vh] font-sans font-bold text-white text-center text-[37px] w-[100vw] xl:w-auto  '>My <span className='text-[#0d6efd]'>  Experience</span></h2>
    <h2 className='lg:text-[3.5vh] font-sans font-bold text-white my-6 text-[18px] lg:my-6 md:my-4 '>Work Experience & Internships</h2>
    <div className='xl:w-[75%] md:w-[100%] h-auto'>
      {Experience.map((e)=>{
        return(
          <div className='xl:w-[75%]md:w-[90%] h-auto p-4 w-[94%] rounded-2xl bg-[#101010] text-white transition-all Box mb-8'>
          <h2 className=' mb-1 xl:mb-2 font-semibold lg:mb-0 text-[22px]'>{e.position}</h2>
          <p className='text-[#adadad] mb-1 lg:mb-0'>( {e.domain} )</p>
          <p className='text-[23px] tracking-[3px] lg:text-[20px] text-[#0d6efd] mb-1 lg:mb-0'> {e.company} </p>
          <p className='text-[#adadad] mb-2 xl:mb-6 lg:mb-2'>{e.date}</p>
          <p className=' text-[15px] xl:text-[18px] lg:text-[15px]'>{e.dis}</p>
    </div>
        )
      })}
          
           
    </div>
    {/* <h2 className='text-[4vh] font-sans font-bold text-white my-10 '>Certifications & Trainings</h2> */}
    <h2 className='xl:text-[4vh] lg:text-[3.5vh] font-sans font-bold text-white xl:my-10 lg:my-6 md:my-4 my-6 text-[18px]'>Recognitions and Awards</h2>
    <div className='xl:w-[75%] md:w-[100%] h-auto p-4 xl:p-8 rounded-2xl bg-[#101010] text-white transition-all Box mb-8'>
      {AwordData.map((e)=>{
        return(
          <div className=' text-[15px] xl:text-[18px] text-white mb-4 lg:text-[15px]'>{e.dis}
        </div>
        )
      })}
     
      

    </div>
    </div>
    </>
  )
}

export default Experence