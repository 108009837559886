import React from 'react'
import './style.css'

const ErrorPage = () => {
  return (
    <>
    
<div className='w-[100vw] h-[100vh] bg-[#09131f] flex justify-center items-center flex-col'>
  
  <h2 className='Kanit font-700 text-[100px] tracking-[25px] shadow-[#fff] text-[#fff]'>404</h2><br/>
  <p className='Kanit font-700 text-[35px] tracking-[5px] shadow-[#fff] text-[#fff]'>You have lost your way</p><br/>
  <a href='/' class="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded">
Return Home
</a >
</div>

    </>
  )
}

export default ErrorPage